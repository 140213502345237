import {Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {combineLatest, EMPTY, Observable, of, ReplaySubject, Subscription, timer} from 'rxjs';
import * as Cesium from 'cesium';
import {CzmlDataSource, Event, Viewer} from 'cesium';
import {CesiumComponent} from '../../common/CesiumComponent';
import {HttpClient} from '@angular/common/http';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {uuidv4} from '../../common/uuid';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import {LegendItemConfig, LegendService} from '../../common/legend.service';
import {UTMEventStore, UtmService} from '../../utm/utm.service';
import {MovableInfoboxComponent} from '../../movable-infobox/movable-infobox.component';

function fromEvent(e: Event): Observable<any[]> {
  return new Observable(observer => {
    const removeCallback = e.addEventListener((...args) => {
      observer.next(args);
    });
    return () => {
      removeCallback();
    };
  });
}

function deepEqual(object1, object2): any {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object): any {
  return object != null && typeof object === 'object';
}

interface EnvelopeBounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

interface ViewDetails {
  bounds: EnvelopeBounds;
  viewWidth: number;
}

function getViewEnvelope(viewer: Viewer): ViewDetails {
  let rect = viewer.camera.computeViewRectangle(viewer.scene.globe.ellipsoid);
  if (rect === undefined) {
    const cl2 = new Cesium.Cartesian2(0, 0);
    const leftTop = viewer.scene.camera.pickEllipsoid(cl2, viewer.scene.globe.ellipsoid);

    const cr2 = new Cesium.Cartesian2(viewer.scene.canvas.width, viewer.scene.canvas.height);
    const rightDown = viewer.scene.camera.pickEllipsoid(cr2, viewer.scene.globe.ellipsoid);

    const leftTopCart = viewer.scene.globe.ellipsoid.cartesianToCartographic(leftTop);
    const rightDownCart = viewer.scene.globe.ellipsoid.cartesianToCartographic(rightDown);
    rect = new Cesium.Rectangle(leftTopCart.longitude, rightDownCart.latitude, rightDownCart.longitude, leftTopCart.latitude);
  }

  const viewWidth = rect.width;

  if (rect.width > .1) {
    const centerLat = (rect.north + rect.south) / 2;
    const centerLon = (rect.east + rect.west) / 2;
    const scale = 0.15 / rect.width;
    return {
      bounds: {
        north: Cesium.Math.toDegrees(centerLat + (rect.height / 2) * scale),
        south: Cesium.Math.toDegrees(centerLat - (rect.height / 2) * scale),
        east: Cesium.Math.toDegrees(centerLon + (rect.width / 2) * scale),
        west: Cesium.Math.toDegrees(centerLon - (rect.width / 2) * scale)
      },
      viewWidth
    };
  } else {
    return {
      bounds: {
        north: Cesium.Math.toDegrees(rect.north),
        south: Cesium.Math.toDegrees(rect.south),
        east: Cesium.Math.toDegrees(rect.east),
        west: Cesium.Math.toDegrees(rect.west)
      },
      viewWidth
    };
  }
}

@Component({
  selector: 'lib-czml-rest-loader',
  templateUrl: './rest-loader.component.html',
  styleUrls: ['./rest-loader.component.css']
})
export class RestLoaderComponent extends CesiumComponent implements OnDestroy {


  @Input() url = '';
  @Input() refreshInterval = -1; // 10*60*1000;
  @Input() legendConfig: LegendItemConfig;
  @Output() terminated: EventEmitter<void> = new EventEmitter();
  private id: string;
  private legendId: string;
  private ds: CzmlDataSource;

  private legendSub: Subscription;
  private czmlSubscription: Subscription;
  private alertSubscription: Subscription;

  private previousCZML: any = {};
  private utmEventStore: UTMEventStore;

  private zoomAlert = document.getElementById('zoom-alert');

  private removeEntityChangeCallback: Event.RemoveCallback;

  private viewer$: ReplaySubject<Viewer> = new ReplaySubject(1);

  private viewDetails$ = this.viewer$.pipe(
    switchMap((viewer: Viewer) =>
      fromEvent(viewer.camera.moveEnd).pipe(map(() => viewer), startWith(viewer))
    ),
    map(viewer => getViewEnvelope(viewer)),
    shareReplay({
      refCount: true,
      bufferSize: 1
    })
  );

  private viewWidth = 0;
  private viewWidth$ = this.viewDetails$.pipe(
    map(({viewWidth}) => viewWidth),
    tap(viewWidth => {
      this.viewWidth = viewWidth;
    })
  );

  private viewBounds: EnvelopeBounds | undefined;
  private viewEnvelope$ = this.viewDetails$.pipe(
    map(({bounds}) => bounds),
    tap(bounds => {
      this.viewBounds = bounds;
    })
  );

  onViewerInit(viewer: Viewer): void {
    this.viewer$.next(viewer);
    this.completeInit();
  }

  ngOnDestroy(): void {
    // TODO: This logic is wrong and could incorrectly hide the connectivity alert
    MovableInfoboxComponent.utmConnectivityIssues = false;
    if (!this.excludedInfrastructure()) {
      MovableInfoboxComponent.restAlertInd = MovableInfoboxComponent.restAlertInd - 1;
    }
    if (MovableInfoboxComponent.restAlertInd === 0 && this.zoomAlert) {
      document.getElementById('zoom-alert').style.display = 'none';
    }
    super.ngOnDestroy();
    this.cesiumService.removeDatasource(this.ds);
    this.czmlSubscription?.unsubscribe();
    this.alertSubscription?.unsubscribe();

    if (this.legendId) {
      this.legendService.removeLegend(this.legendId);
    }

    this.legendSub?.unsubscribe();
    this.utmService?.unregisterEventStore(this.utmEventStore);
  }

  constructor(
    private http: HttpClient,
    @Optional() private legendService: LegendService,
    cesiumService: CesiumService,
    @Optional() private utmService: UtmService
  ) {
    super(cesiumService);
    this.id = uuidv4();

    this.utmEventStore = new UTMEventStore();
    this.utmService?.registerEventStore(this.utmEventStore);

  }

  private excludedInfrastructure(): boolean {
    const excludedLegentIds = new Set([
      'sua',
      'brdrxng',
      'usbordr',
      'operations',
      'constraints'
    ]);
    return excludedLegentIds.has(this.legendConfig.id)
      || this.url.includes('deployment-specific')
      || this.url.includes('sua');

  }

  private displayZoomWarning(zoomLevelExceeded: boolean): void {
    if (MovableInfoboxComponent.restAlertInd <= 0) {
      return;
    }
    if (zoomLevelExceeded) {
      console.warn('Infrastructure has been hidden at the current zoom level to improve performance. Zoom in to continue to view infrastructure.');
    }
    if (this.zoomAlert) {
      document.getElementById('zoom-alert').style.display = zoomLevelExceeded ? 'block' : 'none';
    }
  }

  private computeUrl(url: string, envelope: EnvelopeBounds): string {
    let ret: string;
    if (url.includes('${')) {
      ret = url
        .replace('${envelope_center_lat}', ((envelope.north + envelope.south) / 2).toString())
        .replace('${envelope_center_lon}', ((envelope.east + envelope.west) / 2).toString())
        .replace('${envelope_north}', envelope.north.toString())
        .replace('${envelope_south}', envelope.south.toString())
        .replace('${envelope_east}', envelope.east.toString())
        .replace('${envelope_west}', envelope.west.toString());
    } else {
      ret = url;
    }

    if (ret.includes('${')) {
      console.error(`Potentially missing parameters in url:'${ret}'`);
    }

    return ret;

  }

  private completeInit(): void {
    if (!this.url) {
      return;
    }
    if (!this.excludedInfrastructure()) {
      MovableInfoboxComponent.restAlertInd = MovableInfoboxComponent.restAlertInd + 1;
    }
    const obs: [Observable<ViewDetails>, Observable<unknown>] = [
      this.viewDetails$,
      this.refreshInterval <= 0 ? of(0) : timer(0, this.refreshInterval)
    ];
    const czmls$ = combineLatest(obs).pipe(
      debounceTime(100),
      filter(([viewDetails]) => {
        return viewDetails.viewWidth < .1 || this.excludedInfrastructure();
      }),
      exhaustMap(([viewDetails]) => {
        const newUrl = this.computeUrl(this.url, viewDetails.bounds);
        return this.http.get<any[]>(newUrl).pipe(tap({
          next: (czml) => {
            /** response was 200, make sure you switch connectivity boolean
             * We need a more elegant solution to this in the form of a rewrite.
             */
            MovableInfoboxComponent.utmConnectivityIssues = false;

          },
          error: () => {

            MovableInfoboxComponent.utmConnectivityIssues = true;

            console.error('Error connecting to UTM Service. Attempting to reconnect while UTM is active');
          }
        }), catchError(err => {
          return EMPTY;
        }));
      }),

      tap(() => {
        MovableInfoboxComponent.utmConnectivityIssues = false;
      })
    );
    this.czmlSubscription = czmls$.subscribe((czml) => {
      this.updateCZML(czml);
    });


    this.alertSubscription = this.viewWidth$.subscribe((viewWidth) => {
      this.displayZoomWarning(viewWidth > .1);
    });
    if (this.legendService && this.legendConfig) {
      this.legendId = this.legendService.addLegend(this.legendConfig);

      this.legendSub = this.legendService.watchForLegendEvents(this.legendId).subscribe((legendEvent) => {
        if (!legendEvent) {
          return;
        }
        switch (legendEvent.action) {
          case 'add':
            break;
          case 'remove':
            break;
          case 'click':
            this.terminated.emit();
            break;

        }
      });
    }
  }

  private updateCZML(czml: any): void {
    /** removed because of a bug where infrastructure wouldn't load if zoom
     * level was too close on first instantiation. This component needs an entire
     * rewrite to recitify differences between init methods and screen move
     * event handlers.
     * Re-added as the removal was causing UTM infoboxes to reload (RQ)
     */
    /**
     * Cesium appears to have an issue when there is only a single entity in the CZML array. This if statement should
     * become unnecessary once the issue is resolved. (JS)
     */
    if (deepEqual(this.previousCZML, czml)) {
      return;
    }
    this.previousCZML = JSON.parse(JSON.stringify(czml));

    if (!this.ds) {
      this.ds = new CzmlDataSource();
      this.ds.process({
        id: 'document',
        name: 'rest-loader-' + this.id,
        version: '1.0',
      });
      this.cesiumService.addDatasource(this.ds);
    }


    const toKeep = new Set<string>();
    if (this.removeEntityChangeCallback !== undefined) {
      this.removeEntityChangeCallback();
    }
    this.removeEntityChangeCallback = this.ds.entities.collectionChanged.addEventListener((collection, added, removed, changed) => {
      added.forEach((entity) => {
        toKeep.add(entity.id);
      });
      changed.forEach((entity) => {
        toKeep.add(entity.id);
      });
    });

    this.utmEventStore.setFromCZML(czml);
    this.ds.process(czml).then(() => {
      if (this.removeEntityChangeCallback !== undefined) {
        this.removeEntityChangeCallback();
      }
      /**
       * Create a list of entities based on the changes passed by the event listener which we then loop through
       * and remove from the datasource individually.
       */
      const deadEntities: any[] = [];
      this.ds.entities.values.forEach((entity) => {
        if (!toKeep.has(entity.id)) {
          deadEntities.push(entity);
        }
      });
      deadEntities.forEach((dead) => {
        this.ds.entities.remove(dead);
      });
    }).catch(error => console.error('Error clearing processing and clearing czml entities: ' + error));


  }

}


