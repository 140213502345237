import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Self,
  SimpleChanges,
  SkipSelf,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {AbstractButtonToggle, ButtonState, SafireButtonComponent} from '../safire-button/safire-button.component';
import {Viewer} from 'cesium';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {uuidv4} from '../../common/uuid';

import {CesiumComponent} from '../../common/CesiumComponent';
import {SafireButtonGroupService, SafireGroupEventType} from '../safire-button-group.service';
import {debounceTime, map} from 'rxjs/operators';
import {NoticeService} from '../../common/notice.service';
import {MenuContentComponent} from '../menu-content';
@Component({
  selector: 'lib-safire-button-group',
  templateUrl: './safire-button-group.component.html',
  styleUrls: ['./safire-button-group.component.css', '../../common/css/menu-overlay.css'],
  providers: [
    {provide: AXCesiumWidget, useExisting: forwardRef(() => SafireButtonGroupComponent)},
    {provide: SafireButtonComponent, useExisting: forwardRef(() => SafireButtonGroupComponent)},
    SafireButtonGroupService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SafireButtonGroupComponent extends CesiumComponent implements AbstractButtonToggle,
                                                                           OnInit,
                                                                           OnChanges,
                                                                           OnDestroy,
                                                                           ChildContentContainer {

  @ViewChild('childMenuTemplate') menuTemplate: TemplateRef<any>;
  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any>;
  @Input() showText = false;
  @Input() showAll = true;
  @Input() title = '';
  @Input() settingsId = '';
  @Input() btnIcon = '';
  @ContentChildren(SafireButtonComponent, {descendants: true}) buttons: QueryList<SafireButtonComponent>;
  @ContentChildren(SafireButtonGroupComponent, {descendants: true}) menus: QueryList<SafireButtonGroupComponent>;
  @ContentChildren(MenuContentComponent, {descendants: true}) menuContents: QueryList<MenuContentComponent>;
  menuVisible = false;

  @ViewChild('childContentTemplate') childContentTemplate: TemplateRef<any>;

  allState: ButtonState = ButtonState.OFF;
  allEnabled: boolean;
  mixedState: boolean;
  ON: ButtonState = ButtonState.ON;
  OFF: ButtonState = ButtonState.OFF;
  MIXED: ButtonState = ButtonState.MIXED;
  menuState: ButtonState = ButtonState.OFF;
  menuBaseState: ButtonState = ButtonState.OFF;
  private readonly id: string;
  private openSub: Subscription;
  private parentEventSub: Subscription;
  private subStateSub: Subscription;
  private buttonChangeSub: Subscription;
  private allStateSubject: BehaviorSubject<ButtonState>;
  private menuBaseStateSub: Subscription;

  constructor(cesiumService: CesiumService,
              @Optional() @SkipSelf() private parentSafireButtonGroupService: SafireButtonGroupService,
              @Optional() @Self() private curSafireButtonGroupService: SafireButtonGroupService,
              private  cdrf: ChangeDetectorRef,
              private noticeService: NoticeService
  ) {
    super(cesiumService);
    this.id = uuidv4();
    /*if (this.radarGroupService) {
      this.openSub = this.radarGroupService.listenForOpenNotifications().subscribe((id) => {
        this.menuVisible = this.id === id;
      });
    }*/
    if (this.parentSafireButtonGroupService) {
      this.parentEventSub = this.parentSafireButtonGroupService.listenForEvents().subscribe((event) => {
        switch (event.type) {
          case SafireGroupEventType.CLOSED:
            this.toggleMenu(ButtonState.OFF);
            break;
          case SafireGroupEventType.CHILD_OPEN:
            if (event.sourceId !== this.id) {
              this.toggleMenu(ButtonState.OFF);
            }
        }
      });
    }
    this.allStateSubject = new BehaviorSubject(ButtonState.OFF);
    this.menuBaseStateSub = this.allStateSubject.subscribe((state) => {
      this.menuBaseState = state !== ButtonState.OFF ? ButtonState.MIXED : ButtonState.OFF;
    });
  }

  get generalWidget(): TemplateRef<any> {
    return this.widgetTemplate;
  }

  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  get hasParent(): boolean {
    return this.parentSafireButtonGroupService !== null;
  }

  initStateWatcher(): void {
    const observables = this.buttons.toArray()
      .map(btn => btn.watchState().pipe(map(s => {
        return {btn, state: s};
      })));
    this.subStateSub?.unsubscribe();
    this.subStateSub = combineLatest(observables).pipe(debounceTime(100)).subscribe((states) => {
      const stateSet = new Set(states.map(s => s.state));
      // const newState = stateSet.size > 1 ? ButtonState.MIXED : (stateSet.size === 1 ? stateSet.values().next().value : ButtonState.OFF);
      if (stateSet.size === 1) {
        this.setState(stateSet.values().next().value);
      } else if (stateSet.size === 0) {
        this.setState(ButtonState.OFF, true);
      } else {
        this.setState(ButtonState.MIXED, true);
      }
    });
  }

  onViewerInit(viewer: Viewer): void {
    this.initStateWatcher();
    this.buttonChangeSub?.unsubscribe();
    this.buttonChangeSub = this.buttons.changes.subscribe(() => {
      this.initStateWatcher();
    });
  }

  ngOnInit(): void {

  }


  activate(): void {
  }

  getTitle(): string {
    return '';
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  setAllState(state: ButtonState, propogate: boolean = true): void {
    this.allState = state;
    if (state !== ButtonState.MIXED) {
      this.buttons.forEach(btn => {
        btn.setState(this.allState, false);
      });
    }
    this.updateMenuBaseState();
    if (propogate) {
      this.allStateSubject.next(this.allState);
    }
  }

  setState(state: ButtonState, propogate: boolean = true): void {
    // console.debug("Setting Button Group State");
    this.setAllState(state, propogate);
    this.cdrf.markForCheck();
  }

  watchActivation(): Observable<boolean> {
    return undefined;
  }

  watchState(): Observable<ButtonState> {
    return this.allStateSubject.asObservable();
  }

  toggleAll(): void {
    switch (this.allState) {
      case ButtonState.OFF:
      case ButtonState.MIXED:
        this.allState = ButtonState.ON;
        break;
      case ButtonState.ON:
        this.allState = ButtonState.OFF;
        break;
    }
  }

  handleAllToggle($event: ButtonState): void {
    if (this.allState === $event) {
      return;
    }
    this.allState = $event;
    this.buttons.forEach(btn => {
      btn.setState(this.allState);
    });
    this.allStateSubject.next(this.allState);
    this.updateMenuBaseState();
  }

  toggleMenu($event: ButtonState): void {
    this.menuVisible = $event === ButtonState.ON;
    this.menuState = $event;
    if (this.menuState === ButtonState.OFF) {
      this.curSafireButtonGroupService.notifyOfClose(this.id);
      this.menuState = this.menuBaseState;
    }

    if (this.menuState === ButtonState.ON) {
      this.parentSafireButtonGroupService?.notifyOfChildOpen(this.id);
    }
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.parentEventSub?.unsubscribe();
    this.subStateSub?.unsubscribe();
    this.buttonChangeSub?.unsubscribe();
    this.menuBaseStateSub?.unsubscribe();
  }

  notifySettings(): void {
    console.debug(this.settingsId);
    this.noticeService.notify(this.settingsId);

  }

  private updateMenuBaseState(): void {
    this.menuBaseState = this.allState !== ButtonState.OFF ? ButtonState.MIXED : ButtonState.OFF;
  }
}

import {ChildContentContainer} from "../ChildContentContainer";
